import React from "react";

const NavMenu = () => {
  return (
    <nav className="menu">
      <div className="logo" />
      <ul className="menu__items">
        <li className="menu__item">
          <a href="https://github.com/hramov" className="menu__link">
            Github{" "}
          </a>
        </li>
        <li className="menu__item">
          <a href="https://t.me/therealhramov" className="menu__link">
            Telegram
          </a>
        </li>
        <li className="menu__item">
          <a href="https://barnaul.hh.ru/resume/3bb845edff085c77380039ed1f503377556645" className="menu__link">
            Резюме на HH{" "}
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default NavMenu;
