import React from "react";
import imgMe2 from "../images/me2.jpg";

const Card = () => {
  return (
    <ul className="card">
      <li className="card__item">
        <img src={imgMe2} alt="Фотография главного героя" className="card__item-photo" />
      </li>
      <li className="card__item">
        <h1 className="card__title">Портфолио</h1>
      </li>
      <li className="card__item">
        <p className="card__subtitle">Храмов Сергей</p>
      </li>
      <li className="card__item">
        <p className="card__subtitle">Backend Developer</p>
      </li>
    </ul>
  );
};
export default Card;
