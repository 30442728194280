import React from "react";

const SectionShortBiography = () => {
  return (
    <section className="short-biography">
      <h2 className="section-title">Краткая биография</h2>
      <article className="short-biography__about">
        <p className="short-biography__paragraph">
          Мне 25 лет, из которых более семи я занимаюсь программированием как в рабочее, так и в свободное от основной деятельности время. Было перепробовано множество языков: от Python и JS до C/С++.
        </p>
        <p className="short-biography__paragraph">
          Более целенаправленно начал заниматься программированием после университета. Изучил JavaScript/NodeJS, VueJS и сопутствующие библиотеки. На текущий момент имею опыт коммерческой разработки
          на данном стеке более двух лет.
        </p>
        <p className="short-biography__paragraph">Пишу серверные приложения на TypeScript и Golang, фронтенд на Vue JS.</p>
      </article>
    </section>
  );
};
export default SectionShortBiography;
