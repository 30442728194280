import React from "react";
import NavMenu from "./NavMenu";
import Card from "./Card";

const Header = () => {
  return (
    <header className="header">
      <NavMenu />
      <Card />
    </header>
  );
};
export default Header;
