import React from "react";

const SectionSkills = () => {
  return (
    <section className="skills">
      <h2 className="section-title">Навыки</h2>
      <ul className="skills__items">
        <li className="skills__item">
          <h3 className="skills__item-title">JavaScript / NodeJS</h3>
          <div className="skills__progress">
            <div className="skills__progress-tech skills__progress-tech_width_js" />
          </div>
        </li>
        <li className="skills__item">
          <h3 className="skills__item-title">Typescript</h3>
          <div className="skills__progress">
            <div className="skills__progress-tech skills__progress-tech_width_ts" />
          </div>
        </li>
        <li className="skills__item">
          <h3 className="skills__item-title">Golang</h3>
          <div className="skills__progress">
            <div className="skills__progress-tech skills__progress-tech_width_go" />
          </div>
        </li>
        <li className="skills__item">
          <h3 className="skills__item-title">VueJS</h3>
          <div className="skills__progress">
            <div className="skills__progress-tech skills__progress-tech_width_vue progress" />
          </div>
        </li>
      </ul>
    </section>
  );
};
export default SectionSkills;
