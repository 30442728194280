import React from "react";

const SectionWorkExp = () => {
  return (
    <section className="work-experience">
      <h2 className="section-title">Опыт работы</h2>
      <div className="work-experience__wrap">
        <div className="work-experience__decorative-el" />
        <ul className="work-experience__items">
          <li className="work-experience__item">
            <p className="work-experience__parahraph">20.12.2021 - по н.в.</p>
            <p className="work-experience__parahraph">Главный вычислительный центр ОАО "РЖД"</p>
            <p className="work-experience__parahraph">Ведущий программист отдела автоматизации бизнесс-процессов</p>
            <p className="work-experience__parahraph">Разработка, тестирование, внедрение и сопровождение систем сервис-менеджмента.</p>
          </li>
          <li className="work-experience__item">
            <p className="work-experience__parahraph">12.12.2020 - 20.12.2021</p>
            <p className="work-experience__parahraph">Самостоятельное изучение web-технологий, языка программирования Golang</p>
          </li>
          <li className="work-experience__item">
            <p className="work-experience__parahraph">12.12.2019 - 12.12.2020</p>
            <p className="work-experience__parahraph">Военный инновационный технополис "ЭРА"</p>
            <p className="work-experience__parahraph">Старший группы разработки</p>
            <p className="work-experience__parahraph">
              Разработка элементов системы с использованием Docker-контейнеров, интеграция их с другими элементами и сервисами, руководство членами группы, выполнение требований заказчиков проекта,
              расчет трудозатрат.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};
export default SectionWorkExp;
