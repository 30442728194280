import React from "react";
import SectionShortBiography from "./SectionShortBiography";
import SectionSkills from "./SectionSkills";
import SectionWorkExp from "./SectrionWorkExp";
import SectionProject from "./SectionProject";

const Main = () => {
  return (
    <main className="content">
      <SectionShortBiography />
      <SectionSkills />
      <SectionWorkExp />
      <SectionProject />
    </main>
  );
};
export default Main;
