import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <h2 className="section-title">Тэги</h2>
      <ul className="footer__items">
        <li className="footer__item">Node.js</li>
        <li className="footer__item">HTML5</li>
        <li className="footer__item">CSS3</li>
        <li className="footer__item">TypeScript</li>
        <li className="footer__item">JavaScript</li>
        <li className="footer__item">PostgreSQL</li>
        <li className="footer__item">SQL</li>
        <li className="footer__item">Express.js</li>
        <li className="footer__item">Базы данных</li>
        <li className="footer__item">Рефакторинг кода</li>
        <li className="footer__item">Структуры данных</li>
        <li className="footer__item">Умение разбираться в чужом коде</li>
        <li className="footer__item">Тестирование</li>
        <li className="footer__item">Умение работать в команде</li>
        <li className="footer__item">Коммуникативная компетентность</li>
        <li className="footer__item">Основы компьютерных сетей</li>
        <li className="footer__item">Английский язык</li>
        <li className="footer__item">Golang</li>
        <li className="footer__item">VueJS</li>
        <li className="footer__item">ES6</li>
        <li className="footer__item">Linux</li>
        <li className="footer__item">Intellij IDEA</li>
        <li className="footer__item">REST</li>
        <li className="footer__item">Git</li>
        <li className="footer__item">Разработка ПО</li>
        <li className="footer__item">Автоматизированное тестирование</li>
        <li className="footer__item">Паттерны проектирования</li>
      </ul>
    </footer>
  );
};
export default Footer;
