import React from "react";

const SectionProject = () => {
  return (
    <section className="projects">
      <h2 className="section-title">Проекты</h2>
      <ul className="projects__items">
        <li className="projects__item">
          <div className="projects__item-wrap">
            <p className="projects__item-title">Интернет-магазин нижнего белья в Telegram "AsyaWear"</p>
            <p className="projects__item-subtitle">Клиент-серверное приложение и Telegram-бот для приема заказов на авторский пошив нижнего белья</p>
            <button className="projects__btn">Github</button>
          </div>
        </li>
        <li className="projects__item">
          <div className="projects__item-wrap">
            <p className="projects__item-title">Система учета работы ООО "ЭкоГрадЪ"</p>
            <p className="projects__item-subtitle">Клиент-серверное приложение для автоматизации обработки заказов и сопутствующих документов для проведения экологической экспертизы</p>
            <button className="projects__btn">Github</button>
          </div>
        </li>
        <li className="projects__item">
          <div className="projects__item-wrap">
            <p className="projects__item-title">Wishlist Telegram Bot</p>
            <p className="projects__item-subtitle">Это бот, который помогает в выборе подарков. Можно добавить сколько угодно пользователей Telegram, чтобы видеть их виш-листы</p>
            <button className="projects__btn">Github</button>
          </div>
        </li>
      </ul>
    </section>
  );
};
export default SectionProject;
